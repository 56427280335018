import { trackUser } from '@api/tracking/client';
import type { SearchResult, SearchResultType } from './types';

const SEARCH_PLACEMENT = 'Header';

export function trackSearched(data: SearchResult[]) {
  trackUser({
    event: 'Searched',
    properties: {
      placement: SEARCH_PLACEMENT,
      'search total count': data.length,
      'search folders count': data.filter((d) => d.resultType === 'folder')
        .length,
      'search series count': data.filter((d) => d.resultType === 'series')
        .length,
    },
  });
}

export function trackClickSearchResult(resultType: SearchResultType) {
  trackUser({
    event: 'Clicked search result',
    properties: {
      placement: SEARCH_PLACEMENT,
      'result type': resultType,
    },
  });
}
