import { Text } from '@mentimeter/ragnar-ui';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { useSplits } from '@mentimeter/splitio';
import {
  getTimeAgo,
  NotificationBody,
  NotificationContainer,
  NotificationAvatar,
  NotificationLink,
  NotificationHeader,
} from '../../notification';
import { WorkspaceName } from '../../notification/components/WorkspaceName';
import type { RequestedToJoinWorkspaceNotificationT } from './schema';

export const RequestedToJoinWorkspaceNotification = ({
  notification: {
    id,
    timestamp,
    payload: { senderName, senderProfilePictureUrl },
    status,
  },
  onClick,
}: {
  notification: RequestedToJoinWorkspaceNotificationT;
  onClick: () => void;
}) => {
  const { data: workspace } = useWorkspace();
  const { Mango_Request_Page_V2 } = useSplits(['Mango_Request_Page_V2']);
  const isRequestsPageEnabled = Mango_Request_Page_V2 === 'on';

  const link = isRequestsPageEnabled
    ? '/app/requests'
    : '/app/manage-members?tab=requests';

  return (
    <NotificationLink href={link} onClick={onClick}>
      <NotificationContainer key={id}>
        <NotificationHeader>
          <NotificationAvatar
            name={senderName}
            profilePictureUrl={senderProfilePictureUrl}
          />
        </NotificationHeader>
        <NotificationBody status={status}>
          <Text>
            <Text fontWeight="semiBold">{senderName}</Text> requested to join{' '}
            <WorkspaceName name={workspace?.name} />.
          </Text>
          <Text color="textWeaker">{getTimeAgo(timestamp)} ago</Text>
        </NotificationBody>
      </NotificationContainer>
    </NotificationLink>
  );
};
