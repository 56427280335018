import { useCallback, useState } from 'react';
import { LocalStorage } from '@mentimeter/storage';
import { useUser } from '@mentimeter/user';
import type { SearchResult } from './types';

export interface SearchRecord {
  searchString: string;
  results: SearchResult[];
}

export function useRecentSearches() {
  const { user } = useUser();
  const [recentSearches, setRecentSearches] = useState(getRecentSearches());

  const saveSearch = useCallback(
    (newRecord: SearchRecord) => {
      const savedSearches: SearchRecord[] = getRecentSearches();

      const newSearches = [
        newRecord,
        ...savedSearches.filter(
          (search) => search.searchString !== newRecord.searchString,
        ),
      ].slice(0, 3);
      LocalStorage.setJSONItem({
        type: 'functional',
        key: recentSearchesKey(user?.id.toString() ?? 'no-user'),
        value: newSearches,
      });
    },
    [user?.id],
  );

  const removeSavedSearch = useCallback(
    (searchString: string) => {
      const savedSearches: SearchRecord[] = getRecentSearches();

      const newSearches = savedSearches.filter(
        (search) => search.searchString !== searchString,
      );
      setRecentSearches(newSearches);
      LocalStorage.setJSONItem({
        type: 'functional',
        key: recentSearchesKey(user?.id.toString() ?? 'no-user'),
        value: newSearches,
      });
    },
    [user?.id],
  );

  const updateSearchResult = useCallback(
    (recordToUpdate: SearchRecord) => {
      if (recordToUpdate.results.length === 0) {
        removeSavedSearch(recordToUpdate.searchString);
        return;
      }

      const savedSearches: SearchRecord[] = getRecentSearches();
      const similarRecord = savedSearches.find(
        (search) => search.searchString === recordToUpdate.searchString,
      );

      if (similarRecord) {
        similarRecord.results = recordToUpdate.results;
        setRecentSearches(savedSearches);
        LocalStorage.setJSONItem({
          type: 'functional',
          key: recentSearchesKey(user?.id.toString() ?? 'no-user'),
          value: savedSearches,
        });
      }
    },
    [removeSavedSearch, user?.id],
  );

  return {
    recentSearches,
    saveSearch,
    removeSavedSearch,
    updateSearchResult,
  };
}

function getRecentSearches(): SearchRecord[] {
  return LocalStorage.getJSONItem('mentimeter-recent-searches') ?? [];
}

function recentSearchesKey(userId: string) {
  return `mentimeter-recent-searches-${userId}`;
}
