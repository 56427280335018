import {
  BadgeWrap,
  Box,
  IconButton,
  PopoverBody,
  PopoverContainer,
  PopoverRoot,
  PopoverTrigger,
} from '@mentimeter/ragnar-ui';
import { BellIcon } from '@mentimeter/ragnar-visuals';
import type { ReactNode } from 'react';
import {
  blackAlpha100,
  blackAlpha50,
  blackAlpha500,
} from '@mentimeter/ragnar-colors';
import { UrgentDot } from '../../notification';

const MAX_HEIGHT_THAT_SHOWS_ENOUGH_AND_HINTS_OF_MORE = 438;

export function NotificationCenterPopover({
  children,
  showIndicator,
  onClick,
}: {
  children: ReactNode;
  showIndicator: boolean;
  onClick: () => void;
}) {
  return (
    <PopoverRoot>
      <PopoverTrigger>
        <Box height="100%" justifyContent="center">
          <BadgeWrap
            badge={
              showIndicator && (
                <UrgentDot id="notification-bell-dot" size="14px" />
              )
            }
            placement={0}
          >
            <IconButton
              variant="tertiary"
              borderRadius="full"
              onClick={onClick}
              aria-label="Show notifications"
            >
              <BellIcon size={5} />
            </IconButton>
          </BadgeWrap>
        </Box>
      </PopoverTrigger>
      <PopoverContainer
        showArrow={false}
        align="end"
        sideOffset={12}
        borderRadius="lg"
        extend={() => ({
          boxShadow: undefined,
          overflow: 'visible',
          filter: `drop-shadow(0px 4px 4px ${blackAlpha50}) drop-shadow(0px 0px 1px ${blackAlpha500}) drop-shadow(0px 1px 1px ${blackAlpha100}) drop-shadow(0px 14px 32px ${blackAlpha50})`,
        })}
      >
        <PopoverBody
          borderRadius="xl"
          m={0}
          px="space2"
          pt="space4"
          pb="space3"
          width={408}
          overflow="auto"
          maxHeight={MAX_HEIGHT_THAT_SHOWS_ENOUGH_AND_HINTS_OF_MORE}
          extend={() => ({
            '::-webkit-scrollbar': {
              display: 'none',
            },
          })}
          gap="space3"
          alignItems="stretch"
        >
          {children}
        </PopoverBody>
      </PopoverContainer>
    </PopoverRoot>
  );
}
