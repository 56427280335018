import { userCache } from '@mentimeter/user';
import {
  getRecentRequest,
  getRecentResponse,
} from '@core-api/presentation-collection/presentation-collection/recent';
import { MentiError, captureException } from '@mentimeter/errors';
import useSWR from 'swr';
import {
  appendBackwardCompatibleSeriesParams,
  type GalleryPresentation,
} from '@mentimeter/core-hooks';

export function useRecentPresentations() {
  const { data: series, error } = useSWR<GalleryPresentation[]>(
    'recent-presentations',
    async () => {
      const region = userCache.region;
      const sessionToken = userCache.getToken();

      const response = await fetch(
        getRecentRequest({ region, userAuth: sessionToken }),
      );
      if (!response.ok && response.status !== 401 && response.status !== 404) {
        const e = new MentiError('Error fetching recent presentations', {
          feature: 'recent-presentations',
        });
        captureException(e);
        throw e;
      }

      const data = await getRecentResponse(response);

      return data.series.map((series) =>
        appendBackwardCompatibleSeriesParams(series),
      );
    },
    {
      revalidateOnFocus: false,
    },
  );

  if (error) {
    return {
      loading: false,
      error,
    };
  }

  if (!series) {
    return {
      loading: true,
    };
  }

  return {
    loading: false,
    data: series,
  };
}
