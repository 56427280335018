import useSWR, { mutate } from 'swr';
import type { WorkspaceInviteRequestsResponseT } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';
import { MentiError, captureException } from '@mentimeter/errors';

export interface WorkspaceInviteRequestT {
  id: string;
  email: string;
  name: string;
  expiresAt: string;
  message: string | null;
}

export const GET_WORKSPACE_INVITE_REQUESTS_KEY =
  'get-user-workspace-invite-requests';

export function useWorkspaceInviteRequests({
  workspaceId,
  isOwnerOrAdmin,
}: {
  workspaceId: number | undefined;
  isOwnerOrAdmin: boolean;
}) {
  const skip = !workspaceId || !isOwnerOrAdmin;
  return useSWR(skip ? null : GET_WORKSPACE_INVITE_REQUESTS_KEY, async () => {
    if (!workspaceId) return [];

    return fetcher(String(workspaceId));
  });
}

export const mutateGetWorkspaceInviteRequests = () =>
  mutate(GET_WORKSPACE_INVITE_REQUESTS_KEY);

async function fetcher(
  workspaceId: string,
): Promise<WorkspaceInviteRequestT[]> {
  try {
    const { data } = await core().workspaces.inviteRequests.get(workspaceId);

    return data.map((item: WorkspaceInviteRequestsResponseT) => {
      return {
        id: item.id,
        name: item.user_name,
        email: item.user_email,
        expiresAt: item.expires_at,
        message: item.message,
      };
    });
  } catch (error) {
    captureException(
      new MentiError('could not fetch workspace invites', {
        cause: error,
        feature: 'managing-organizations',
      }),
    );
    return [];
  }
}
