import { NOTIFICATION_MAP } from './notifications';
import type {
  NotificationComponent,
  NotificationOf,
  NotificationType,
} from './types';

export function NotificationRenderer<T extends NotificationType>({
  notification,
  onClick,
}: {
  notification: NotificationOf<T>;
  onClick: () => void;
}) {
  const NotificationComponent = getComponentFromType(notification.type);

  if (!NotificationComponent) {
    return null;
  }

  return (
    <NotificationComponent notification={notification} onClick={onClick} />
  );
}

function getComponentFromType<T extends NotificationType>(
  type: T,
): NotificationComponent<T> {
  return NOTIFICATION_MAP[type];
}
