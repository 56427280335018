import useSWR from 'swr';
import {
  getNotificationsRequest,
  getNotificationsResponse,
} from '@core-api/notifications/notifications';
import { userCache } from '@mentimeter/user';
import { type SafeParseReturnType, type SafeParseSuccess } from 'zod';
import { notificationSchema } from '../notifications/schema';
import type { NotificationT } from '../notifications/types';
import {
  genericSchema,
  type GenericNotificationT,
} from '../notifications/generic';
import { NOTIFICATIONS_CACHE_KEY } from './constants';
import { useNotificationFixtures } from './useNotificationFixtures';

interface NotificationDataT {
  notifications: Array<NotificationT | GenericNotificationT>;
  isLoading: boolean;
  notificationsEnabled: boolean;
  error?: Error;
}

const IS_DEV = process.env.NODE_ENV === 'development';

export function useNotifications(): NotificationDataT {
  const notificationFixtures = useNotificationFixtures();

  const results = useSWR(NOTIFICATIONS_CACHE_KEY, fetchNotifications);

  if (notificationFixtures === 'loading')
    return {
      notifications: [],
      isLoading: true,
      notificationsEnabled: true,
    };

  if (notificationFixtures instanceof Error) {
    return {
      error: notificationFixtures,
      notifications: [],
      isLoading: false,
      notificationsEnabled: true,
    };
  }

  if (results.error) {
    return {
      error: results.error,
      notifications: [],
      isLoading: false,
      notificationsEnabled: true,
    };
  }

  if (!results.data) {
    return {
      notifications: [],
      isLoading: true,
      notificationsEnabled: true,
    };
  }

  const data = notificationFixtures || results.data.notifications;

  const notificationParseResults = data.map(parseNotification);
  const hasParsingErrors = notificationParseResults.some(
    (result) => !isSuccessfulNotification(result),
  );

  if (IS_DEV && hasParsingErrors) {
    // eslint-disable-next-line no-console
    console.error(
      `Something went wrong parsing one or more notifications. Check the content of your notifications for unexpected values.`,
    );
  }

  const notifications = notificationParseResults
    .filter(isSuccessfulNotification)
    .map((result) => result.data)
    .sort((a, b) => b.timestamp.localeCompare(a.timestamp));

  return {
    notifications,
    isLoading: false,
    notificationsEnabled: true,
  };
}

async function fetchNotifications() {
  const request = getNotificationsRequest({
    region: userCache.region,
    userAuth: userCache.getToken(),
  });

  const response = await fetch(request);
  return await getNotificationsResponse(response);
}

function parseNotification(notification: unknown) {
  const schema = genericSchema.or(notificationSchema);
  const result = schema.safeParse(notification);
  return result;
}

function isSuccessfulNotification<T>(
  result: SafeParseReturnType<T, T>,
): result is SafeParseSuccess<T> {
  return result?.success && !!result.data;
}
