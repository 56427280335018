// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface GetRecentRequest {
  region: 'eu' | 'us';
  userAuth: string;
}

export function getRecentRequest(requestParams: GetRecentRequest): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/presentation-collection/recent`;
  return new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
