// This file is automatically generated. DO NOT EDIT.

import { type Presentation } from '../../types/response/Presentation';
import { fromSnakeKeys } from '@api/internal';

export interface GetRecentResponse {
  /** The user's most recently visited presentations */
  series: Array<Presentation>;
}

export async function getRecentResponse(
  response: Response,
): Promise<GetRecentResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as GetRecentResponse;
}
