import { userCache } from '@mentimeter/user';
import { postMarkReadRequest } from '@core-api/notifications/notifications/mark-read';
import useSWRMutation from 'swr/mutation';
import { useDevelopmentFlag } from '@mentimeter/development-tools/hooks';
import { NOTIFICATIONS_CACHE_KEY } from './constants';

export function useMarkNotificationAsRead() {
  const notificationFixtures = useDevelopmentFlag('notification-fixtures');
  const { trigger: markRead } = useSWRMutation(
    NOTIFICATIONS_CACHE_KEY,
    async (
      _key,
      { arg: { notificationId } }: { arg: { notificationId: string } },
    ) => {
      const region = userCache.region;
      const sessionToken = userCache.getToken();

      await fetch(
        postMarkReadRequest({
          region,
          userAuth: sessionToken,
          notificationId,
        }),
      );
    },
  );

  if (notificationFixtures) {
    return () => {
      // eslint-disable-next-line no-console
      console.log('Marking notification as read');
    };
  }

  return markRead;
}
