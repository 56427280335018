import type {
  SortKey,
  SortKeys,
  SortOrder,
} from 'features/dashboard/presentation-view/types';

export const DUPLICATING_KEY = 'DUPLICATING';
export const ROOT_FOLDER_ID = null;
export const GALLERY_ROOT_FOLDER = {
  id: ROOT_FOLDER_ID,
  name: 'Home',
  createdAt: '',
  updatedAt: '',
  parentId: 0,
  presentationsCount: 0,
  ownerId: 0,
  ownerDisplayName: '',
  workspaceId: null,
  shared: false,
};

export const NAME_MAX_CHAR = 100;

export const MAXIMUM_FOLDER_DEPTH = 4;
export const MAXIMUM_SHARED_FOLDER_DEPTH = 1;

export const isSortOrder = (value: string | undefined): value is SortOrder =>
  value === 'asc' || value === 'desc';

export const SORT_KEYS: SortKeys = Object.freeze({
  updatedAt: 'updatedAt',
  createdAt: 'createdAt',
  name: 'name',
  ownerDisplayName: 'ownerDisplayName',
});

export const isSortKey = (value: string | undefined): value is SortKey => {
  if (!value) return false;
  return value in SORT_KEYS;
};

export const SORT_KEY_TO_LABEL: Record<SortKey, string> = Object.freeze({
  [SORT_KEYS.updatedAt]: 'Last modified',
  [SORT_KEYS.createdAt]: 'Last created',
  [SORT_KEYS.name]: 'Name',
  [SORT_KEYS.ownerDisplayName]: 'Created by',
});

export const DEFAULT_SORT_BY_ORDER: Record<SortKey, SortOrder> = {
  createdAt: 'desc',
  updatedAt: 'desc',
  ownerDisplayName: 'asc',
  name: 'asc',
};

export const NEW_PRES_BUTTON_ID = 'new-presentation-button-hotkey';
export const NEW_FOLDER_BUTTON_ID = 'new-folder-button-hotkey';

export const TRACKING_PLACEMENT = Object.freeze({
  BULK_ACTION: 'Bulk action',
  ACTION_MENU: 'Dashboard row menu',
  DRAG_AND_DROP: 'Dashboard drag and drop',
  PLAY_ICON: 'Play icon',
  PRESENTATION_TABLE: 'Presentation table',
  PRESENTATION_GRID: 'Presentation grid',
  RECENT_PRESENTATIONS: 'Recent presentations',
  RECENT_PRESENTATIONS_SHARE_RESULTS: 'Recent presentations share results',
  RECENT_PRESENTATIONS_ADD_MEMBERS: 'Recent presentations add members',
  CREATE_NEW_PRESENTATION_MODAL: 'Create new presentation modal',
  BREADCRUMBS: 'Breadcrumbs',
} as const);

export type TrackingPlacementT =
  (typeof TRACKING_PLACEMENT)[keyof typeof TRACKING_PLACEMENT];

export const DASHBOARD_TYPE = Object.freeze({
  PRIVATE: 'private',
  SHARED_WITH_ME: 'sharedWithMe',
  WORKSPACE: 'workspace',
} as const);
