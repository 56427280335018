import { Text } from '@mentimeter/ragnar-ui';
import {
  getTimeAgo,
  NotificationBody,
  NotificationContainer,
  NotificationAvatar,
  NotificationLink,
  NotificationHeader,
} from '../../notification';
import { truncate } from '../../notification/utils/truncate';
import type { RepliedToCommentNotificationT } from './schema';

export function RepliedToCommentNotification({
  notification: {
    id,
    timestamp,
    payload: { senderName, senderProfilePictureUrl, seriesName, commentLink },
    status,
  },
  onClick,
}: {
  notification: RepliedToCommentNotificationT;
  onClick: () => void;
}) {
  const truncatedResourceName = truncate(seriesName);

  return (
    <NotificationLink href={commentLink} onClick={onClick}>
      <NotificationContainer key={id}>
        <NotificationHeader>
          <NotificationAvatar
            name={senderName}
            profilePictureUrl={senderProfilePictureUrl}
          />
        </NotificationHeader>
        <NotificationBody status={status}>
          <Text>
            <Text fontWeight="semiBold">{senderName}</Text> replied to your
            comment on{' '}
            <Text fontWeight="semiBold">{truncatedResourceName}</Text>.
          </Text>
          <Text color="textWeaker">{getTimeAgo(timestamp)} ago</Text>
        </NotificationBody>
      </NotificationContainer>
    </NotificationLink>
  );
}
