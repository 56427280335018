import {
  Box,
  Clickable,
  ClickableIcon,
  Text,
  TextInput,
} from '@mentimeter/ragnar-ui';
import {
  CrossIcon,
  LoaderIcon,
  ResetResultsIcon,
  SearchIcon,
  UsersIcon,
} from '@mentimeter/ragnar-visuals';
import React, { useEffect, useMemo, useState } from 'react';
import { LinkClickable, useRouter } from '@mentimeter/next-navigation';
import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { useSearchExperiment } from 'features/experiment-search/useSearchExperiment';
import { useRecentPresentations } from 'features/home/widgets/recent-presentations/useRecentPresentations';
import { createFolderURL } from 'features/dashboard/router';
import { DashboardTypeEnum } from 'features/dashboard/presentation-view/types';
import type { GalleryPresentation } from '@mentimeter/core-hooks';
import type { SearchRecord } from './use-recent-searches';
import type { OtherResult, SearchState } from './use-search';
import { useSearch } from './use-search';
import { useContainerFocus } from './use-container-focus';
import { trackClickSearchResult } from './tracking';
import type {
  SearchResult,
  SearchResultType,
  SearchSharingType,
} from './types';

const INPUT_REFERENCE_ID = 'omni-search-input';
const PLACEHOLDER_TEXT = 'Search presentations, folders, and pages';
const WIDTH = 500;

type SearchComponentProps = ReturnType<typeof useSearch> & {
  recentPresentations: GalleryPresentation[];
  highContrast?: boolean;
};

interface ResultItem {
  url: string;
  title: string;
  subtitle: string;
  subtitleIcon?: React.ReactNode;
  resultType: SearchResultType;
}

export function Search() {
  const { isInExperiment } = useSearchExperiment();

  if (!isInExperiment) return null;

  return <SearchContainer />;
}

function SearchContainer() {
  const {
    // Search series + questions + folders
    search,
    searchInput,
    searchState,
    searchResultsState,
    lastSearched,
    cancelSearch,
    searchResults,

    // Recent searches
    recentSearches,
    saveSearch,
    removeSavedSearch,
    restoreSavedSearch,

    // Other results
    otherResults,
  } = useSearch();
  const { data: recentPresentationsData } = useRecentPresentations();
  const recentPresentations = recentPresentationsData ?? [];

  return (
    <SearchComponent
      searchInput={searchInput}
      search={search}
      searchState={searchState}
      searchResultsState={searchResultsState}
      cancelSearch={cancelSearch}
      searchResults={searchResults}
      lastSearched={lastSearched}
      recentSearches={recentSearches}
      saveSearch={saveSearch}
      removeSavedSearch={removeSavedSearch}
      restoreSavedSearch={restoreSavedSearch}
      recentPresentations={recentPresentations}
      otherResults={otherResults}
    />
  );
}

function SearchComponent({
  highContrast = false,
  searchInput,
  search,
  searchState,
  searchResultsState,
  cancelSearch,
  searchResults,
  lastSearched,
  recentSearches,
  saveSearch,
  removeSavedSearch,
  restoreSavedSearch,
  recentPresentations,
  otherResults,
}: SearchComponentProps) {
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const searchContainerRef = React.useRef<HTMLDivElement | null>(null);
  const { isActive, forceDeactivate } = useContainerFocus(searchContainerRef);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const router = useRouter();

  const showDrawer =
    isActive &&
    (searchResultsState !== 'idle' ||
      recentSearches.length > 0 ||
      recentPresentations.length > 0 ||
      otherResults.length > 0);

  const drawerGroups = useMemo(
    () =>
      [
        {
          title: 'Recent searches',
          items: recentSearches,
          type: 'search',
          visible: searchResultsState === 'idle' && recentSearches.length > 0,
        },
        {
          title: null,
          items: searchResults.map(fromSearchHitToResult),
          type: 'result',
          visible:
            searchResultsState === 'has-results' && searchResults.length > 0,
          onAction: () => {
            saveSearch();
          },
        },
        {
          title: 'Recently viewed',
          items: recentPresentations.map(fromSeriesToResult),
          type: 'result',
          visible:
            searchResultsState === 'idle' && recentPresentations.length > 0,
          onAction: null,
        },
        {
          title: 'Other results',
          items: otherResults.map(fromOtherResultToResult),
          type: 'result',
          visible: searchResultsState !== 'idle' && otherResults.length > 0,
          onAction: null,
        },
      ] as const,
    [
      recentSearches,
      searchResultsState,
      searchResults,
      recentPresentations,
      otherResults,
      saveSearch,
    ],
  );

  const visibleItems = useMemo(
    () =>
      drawerGroups.reduce(
        (acc, group) => (group.visible ? acc.concat(group.items) : acc),
        [] as Array<SearchRecord | ResultItem>,
      ),
    [drawerGroups],
  );

  return (
    <Box
      ref={searchContainerRef}
      width="100%"
      maxWidth={WIDTH}
      zIndex={3}
      py="space2"
      extend={() => ({
        alignSelf: 'flex-start',
      })}
    >
      <Box position="absolute" width="100%">
        <Box
          height="auto"
          width="100%"
          // Show interactive border color when component is active:
          borderColor={isActive ? 'secondary' : 'transparent'}
          borderStyle="solid"
          borderWidth={1}
          borderRadius="lg"
          bg={isActive ? 'bg' : highContrast ? 'bgStrong' : 'inputBg'}
          overflow="hidden"
          alignItems="stretch"
          extend={({ theme }) => ({
            outline: isActive
              ? `4px solid ${theme.colors.interactiveFocused}`
              : 'none',
            transition: `box-shadow ${theme.durations[0]}s ease`,
            // Show same interactive border as when active, on hover:
            ':hover': {
              border:
                `${theme.borders[1]}px solid ` + theme.colors['secondary'],
            },
          })}
          position="relative"
        >
          <SearchInput
            ref={searchInputRef}
            searchInput={searchInput}
            searchState={searchState}
            onChange={onChange}
            onClear={onClear}
            onKeyDown={onKeyDown}
          />
          {showDrawer && (
            <Box>
              {searchResultsState === 'no-results' && (
                <DrawerSection>
                  <NoMatchesMessage lastSearched={lastSearched} />
                </DrawerSection>
              )}

              {drawerGroups
                .filter(({ visible }) => visible)
                .map((drawerGroup, groupIndex) => {
                  if (drawerGroup.type === 'search') {
                    const { title, items } = drawerGroup;
                    return (
                      <React.Fragment key={groupIndex}>
                        {title && (
                          <DrawerSection title={title}>
                            {items.map((searchRecord) => (
                              <DrawerItem
                                key={searchRecord.searchString}
                                label={searchRecord.searchString}
                                onSelect={() =>
                                  restoreSavedSearch(searchRecord)
                                }
                                highlighted={
                                  visibleItems.indexOf(searchRecord) ===
                                  highlightedIndex
                                }
                              >
                                <SearchRecordItem
                                  onClick={() =>
                                    restoreSavedSearch(searchRecord)
                                  }
                                  onRemove={() =>
                                    removeSavedSearch(searchRecord.searchString)
                                  }
                                  searchRecord={searchRecord}
                                  highContrast={highContrast}
                                />
                              </DrawerItem>
                            ))}
                          </DrawerSection>
                        )}
                      </React.Fragment>
                    );
                  }

                  const { title, items, onAction } = drawerGroup;

                  return (
                    <React.Fragment key={groupIndex}>
                      <DrawerSection title={title}>
                        {items.map((item) => (
                          <DrawerItem
                            key={item.url}
                            label={item.title}
                            onSelect={() => {
                              onAction?.();
                              router.push(item.url);
                            }}
                            highlighted={
                              visibleItems.indexOf(item) === highlightedIndex
                            }
                          >
                            <ResultItem
                              onClick={() => {
                                onAction?.();
                                trackClickSearchResult(item.resultType);
                              }}
                              url={item.url}
                              title={item.title}
                              subtitle={item.subtitle}
                              subtitleIcon={item.subtitleIcon}
                              highContrast={highContrast}
                            />
                          </DrawerItem>
                        ))}
                      </DrawerSection>
                    </React.Fragment>
                  );
                })}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    search(e.target.value);
  }

  function onClear() {
    cancelSearch();
    searchInputRef.current?.focus();
    setHighlightedIndex(-1);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Escape') {
      forceDeactivate();

      setHighlightedIndex(-1);

      const activeElement = document.activeElement as HTMLElement;
      activeElement?.blur?.();
    } else if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      const itemCount = drawerGroups.reduce(
        (acc, group) => (group.visible ? acc + group.items.length : acc),
        0,
      );

      if (e.key === 'ArrowDown') {
        e.preventDefault();

        const nextIndex = Math.min(
          itemCount - 1,
          Math.max(0, highlightedIndex + 1),
        );
        setHighlightedIndex(nextIndex);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();

        const nextIndex = Math.min(
          itemCount - 1,
          Math.max(0, highlightedIndex - 1),
        );
        setHighlightedIndex(nextIndex);
      }
    }
  }
}

interface SearchInputProps {
  searchInput: string;
  searchState: SearchState;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (
    { searchInput, searchState, onChange, onKeyDown, onClear },
    forwardedRef,
  ) => {
    const iconTrailing = trailingIcon(searchState, searchInput.length > 0);

    return (
      <TextInput
        ref={forwardedRef}
        value={searchInput}
        className={`${INPUT_REFERENCE_ID} enabled:shadow-none focus:enabled:shadow-none`} // override Tailwind classes
        onKeyDown={onKeyDown}
        onChange={onChange}
        iconTrailingAction={onClear}
        iconLeading={<LeadingIcon />}
        {...(iconTrailing && { iconTrailing })}
        placeholder={PLACEHOLDER_TEXT}
        inputSize="compact"
        width="100%"
        extend={({ theme }) => ({
          '::placeholder': {
            color: theme.colors.textWeaker,
          },

          // Ensure input has color of the parent element
          background: 'inherit',

          // Ensure long text is truncated when not editing
          textOverflow: 'ellipsis',

          /*
            Since the border needs to expand from the input field to envolpe the search results as well,
            the border needs to be placed on a level above. Which means that the natural size of input without borders
            (which would be 40) has to be changed to exclude the border sizes (total of 4px).
          */
          height: '36px',

          // Disable the default input styling, they are instead applied to the parent element.
          border: '0',
          boxShadow: 'none',
          outline: '0',
          ':focus': {
            boxShadow: '0',
            outline: 'none',
          },
          ':focus:hover': {
            boxShadow: '0',
            outline: 'none',
          },
          ':focus-visible': {
            boxShadow: '0',
            outline: 'none',
          },
          '@media (hover: hover)': {
            '&:hover:enabled': {
              border: '0',
              boxShadow: 'none',
              background: 'transparent',
            },
          },

          // Align icons and text to center (dislocated because of the parents borders)
          position: 'relative',
        })}
      />
    );
  },
);

function DrawerItem({
  highlighted,
  onSelect,
  children,
  label,
}: {
  highlighted: boolean;
  onSelect(): void;
  children: React.ReactNode;
  label: string;
}) {
  useEffect(() => {
    if (!highlighted) return;

    const onKeyDown = (e: KeyboardEvent) => {
      if (!isTarget(e.target as HTMLElement, INPUT_REFERENCE_ID)) return;

      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();

        onSelect();
      }
    };
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <Box
      data-selectable
      bg={highlighted ? 'neutralWeakest' : 'transparent'}
      alignItems="stretch"
      aria-pressed={highlighted}
      aria-label={label}
    >
      {children}
    </Box>
  );
}

function DrawerSection({
  title,
  children,
}: {
  title?: string | null;
  children: React.ReactNode;
}) {
  return (
    <>
      <Divider />
      <Box pb={1} pt={2} width="100%" alignItems="stretch">
        {title && (
          <Text ml={3} my={1} fontSize={1} color="textWeak" data-skip-select>
            {title}
          </Text>
        )}
        {children}
      </Box>
    </>
  );
}

function LeadingIcon() {
  return (
    <Box>
      <SearchIcon />
    </Box>
  );
}

function trailingIcon(searchState: SearchState, hasResults: boolean) {
  return searchState === 'searching' ? (
    <Box>
      <LoaderIcon />
    </Box>
  ) : hasResults ? (
    <Box>
      <CrossIcon />
    </Box>
  ) : null;
}

function Divider() {
  return (
    <Box alignItems="center">
      <Box
        width="94%"
        borderBottomWidth="2px"
        borderColor="border"
        borderStyle="solid"
      />
    </Box>
  );
}

function NoMatchesMessage({ lastSearched }: { lastSearched: string }) {
  return (
    <Text
      pt={1}
      pb={2}
      px={3}
      fontSize={1}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      No search results for{' '}
      <Text fontWeight="semiBold" fontSize={1} p={0} m={0}>
        {lastSearched}
      </Text>
    </Text>
  );
}

function SearchRecordItem({
  searchRecord,
  onClick,
  onRemove,
  highContrast,
}: {
  searchRecord: SearchRecord;
  onClick(): void;
  onRemove(): void;
  highContrast: boolean;
}) {
  return (
    <Box
      extend={({ theme }) => ({
        ':hover': {
          background: highContrast
            ? theme.colors['surfaceRaised']
            : theme.colors['neutralWeakest'],
        },
        '&:hover a:hover': {
          opacity: 1,
        },
      })}
      width="100%"
      flexDirection="row"
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Clickable
        py={2}
        pl={3}
        flex={1}
        height="100%"
        flexDirection="row"
        title="Restore search"
        tabIndex={-1}
        onMouseDown={(e) => {
          // Prevent input field from losing focus
          e.stopPropagation();
          e.preventDefault();
          onClick();
        }}
      >
        <ResetResultsIcon color="textWeak" />
        <Text ml={2} fontSize={1} color="textWeak">
          {searchRecord.searchString}
        </Text>
      </Clickable>
      <ClickableIcon
        my={2}
        mr={3}
        justifyContent="center"
        onMouseDown={(e) => {
          // Prevent input field from losing focus
          e.stopPropagation();
          e.preventDefault();

          onRemove();
        }}
        flex="0 0 auto"
      >
        <CrossIcon color="textWeak" />
      </ClickableIcon>
    </Box>
  );
}

function ResultItem({
  url,
  title,
  subtitle,
  subtitleIcon,
  onClick = () => {},
  highContrast,
}: {
  url: string;
  title: string;
  subtitle: string;
  subtitleIcon: React.ReactNode;
  onClick?: () => void;
  highContrast: boolean;
}) {
  return (
    <LinkClickable
      justifyContent="center"
      href={url}
      py={2}
      px={3}
      onClick={onClick}
      tabIndex={-1}
      extend={({ theme }) => ({
        ':hover': {
          background: highContrast
            ? theme.colors['surfaceRaised']
            : theme.colors['neutralWeakest'],
        },
        '&:hover a:hover': {
          opacity: 1,
        },
      })}
    >
      <Box
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontSize={1} fontWeight="semiBold" pr={2} title={title} truncate>
          {title}
        </Text>
        <Box flexDirection="row" ml={2}>
          <Box alignItems="center" top="1px" mr={1}>
            {subtitleIcon}
          </Box>
          <Text fontSize={1} color="textWeaker" textAlign="right">
            {subtitle}
          </Text>
        </Box>
      </Box>
    </LinkClickable>
  );
}

const SHARING_TYPE_TO_DASHBOARD_TYPE: Record<
  SearchSharingType,
  DashboardTypeEnum
> = {
  private: DashboardTypeEnum.PRIVATE,
  workspace: DashboardTypeEnum.WORKSPACE,
  user_sharing: DashboardTypeEnum.SHARED_WITH_ME,
};

function fromSearchHitToResult(hit: SearchResult): ResultItem {
  const dashboardType = SHARING_TYPE_TO_DASHBOARD_TYPE[hit.sharingType];
  return {
    url: hit.seriesId
      ? buildPresentationPath({
          questionId: hit.questionId,
          seriesId: hit.seriesId,
          mode: 'edit',
        })
      : hit.folderId
        ? createFolderURL(hit.folderId, dashboardType)
        : '/app/home',
    title: hit.questionTitle ?? hit.seriesName ?? hit.folderName ?? '',
    subtitleIcon: hit.sharingType === 'private' ? null : <SharedIcon />,
    subtitle: hit.questionTitle
      ? 'Slide'
      : hit.folderName
        ? 'Folder'
        : 'Presentation',
    resultType: hit.resultType,
  };
}

function fromSeriesToResult(series: GalleryPresentation): ResultItem {
  return {
    url: buildPresentationPath({
      seriesId: series.id,
      mode: 'edit',
    }),
    title: series.name,
    subtitleIcon: series.workspaceId ? <SharedIcon /> : null,
    subtitle: 'Presentation',
    resultType: 'recently-viewed',
  };
}

function fromOtherResultToResult(result: OtherResult): ResultItem {
  return {
    url: result.url,
    title: result.title,
    subtitle: result.category,
    resultType: 'page',
  };
}

function isTarget(targetElement: HTMLElement, targetClassName: string) {
  return (
    targetElement.classList && targetElement.classList.contains(targetClassName)
  );
}

function SharedIcon() {
  return (
    <UsersIcon color="textWeaker" size={1} aria-label="Shared presentation" />
  );
}
