import { z } from 'zod';
import { baseNotificationSchema } from '../../notification';

export const INVITED_TO_RESOURCE_TYPE = 'invited-to-resource';
export const invitedToResourceSchema = baseNotificationSchema.extend({
  type: z.literal(INVITED_TO_RESOURCE_TYPE),
  payload: z.object({
    senderName: z.string(),
    senderEmail: z.string(),
    senderProfilePictureUrl: z.string().nullable().optional(),
    resourceType: z.union([z.literal('series'), z.literal('folder')]),
    resourceName: z.string(),
    inviteLink: z.string(),
  }),
});

export type InvitedToResourceNotificationT = z.infer<
  typeof invitedToResourceSchema
>;
