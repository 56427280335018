import { Box, Link, Text } from '@mentimeter/ragnar-ui';
import { trackUser } from '@api/tracking/client';

export function UserMenuUpgradeNotice() {
  return (
    <Box
      width="auto"
      bg="brandWeakest"
      p="space3"
      maxWidth={250}
      borderRadius="xl"
      mb="space2"
    >
      <Text color="onBrandWeakest" fontSize={1}>
        <Link
          color="currentColor"
          display="inline"
          underline={false}
          fontWeight="semiBold"
          href="/plans"
          target="_blank"
          onClick={() => {
            trackUser({
              event: 'Plans from homeview',
              properties: {
                context: 'User menu',
              },
            });
          }}
        >
          Upgrade
        </Link>{' '}
        for unlimited participants, exporting results and much more
      </Text>
    </Box>
  );
}
