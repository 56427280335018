import { z } from 'zod';
import { baseNotificationSchema } from '../../notification';

export const INVITED_TO_WORKSPACE_TYPE = 'invited-to-workspace';

export const invitedToWorkspaceSchema = baseNotificationSchema.extend({
  type: z.literal(INVITED_TO_WORKSPACE_TYPE),
  payload: z.object({
    senderName: z.string(),
    senderEmail: z.string(),
    senderProfilePictureUrl: z.string().nullable().optional(),
    workspaceName: z.string().nullable().optional(),
    seriesName: z.string().nullable().optional(),
    inviteLink: z.string(),
  }),
});

export type InvitedToWorkspaceNotificationT = z.infer<
  typeof invitedToWorkspaceSchema
>;
