import * as React from 'react';
import type { DropdownMenuContentProps } from './DropdownMenuContent';
import { DropdownMenuContent } from './DropdownMenuContent';
import { DropdownMenuPortal } from './DropdownMenuPortal';
import { DropdownMenuArrow } from './DropdownMenuArrow';

interface DropdownMenuContainerProps extends DropdownMenuContentProps {
  withPortal?: boolean;
  showArrow?: boolean;
}

export const DropdownMenuContainer = React.forwardRef(
  (
    {
      withPortal = true,
      showArrow = true,
      children,
      ...contentProps
    }: DropdownMenuContainerProps,
    forwardedRef,
  ) => {
    const content = (
      <DropdownMenuContent ref={forwardedRef} {...contentProps}>
        {showArrow && <DropdownMenuArrow />}
        {children}
      </DropdownMenuContent>
    );

    return withPortal ? (
      <DropdownMenuPortal>{content}</DropdownMenuPortal>
    ) : (
      content
    );
  },
);
