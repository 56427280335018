/*
  Observes when the container loses focus, ignoring when child elements get focus.

  Also looks at if a click is made outside the container.
 */
import React, { useState } from 'react';

export function useContainerFocus(
  searchContainerRef: React.RefObject<HTMLDivElement | null>,
) {
  const [isActive, setIsActive] = useState(false);

  const detectClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (event.target === null) return;

      const target = event.target as Element;
      if (!searchContainerRef.current?.contains(target)) {
        deactivate();
      }
    },
    [searchContainerRef],
  );
  const detectFocusOutside = React.useCallback(
    (event: FocusEvent) => {
      if (event.target === null) return;

      const target = event.target as Element;
      if (
        target === searchContainerRef.current ||
        searchContainerRef.current?.contains(target)
      ) {
        activate();
      } else {
        deactivate();
      }
    },
    [searchContainerRef],
  );

  React.useEffect(() => {
    document.body.addEventListener('mousedown', detectClickOutside);
    document.body.addEventListener('focusin', detectFocusOutside);

    return () => {
      document.body.removeEventListener('mousedown', detectClickOutside);
      document.body.removeEventListener('focusin', detectFocusOutside);
    };
  }, [detectClickOutside, detectFocusOutside, isActive]);

  return {
    isActive,
    forceDeactivate: deactivate,
  };

  function activate() {
    setIsActive(true);
  }

  function deactivate() {
    setIsActive(false);
  }
}
