import React from 'react';
import { useUser } from '@mentimeter/user';
import type { TrackingContext } from '@mentimeter/http-clients';
import {
  TrackingPlacement,
  UserFeaturesTypeEnum,
} from '@mentimeter/http-clients';
import { StarSolidIcon } from '@mentimeter/ragnar-visuals';
import { PopoverFooter } from '@mentimeter/ragnar-ui';
import { trackUser } from '@api/tracking/client';
import {
  RequestUpgradeModal,
  RequestUpgradeButton,
} from '../lib/request-upgrade';
import { useFeatureAccess } from '../lib/hooks/use-feature-access';
import { UpgradeButton } from './UpgradeButton';

export function EngagementLimitProgressUpgradeButton({
  trackingContext,
  onClick,
}: {
  trackingContext: TrackingContext | undefined;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const { user } = useUser();
  const accessLevels = useFeatureAccess(UserFeaturesTypeEnum.BASIC);

  if (!user || accessLevels === 'has-access') return null;

  if (accessLevels === 'not-in-role') {
    return (
      <RequestUpgradeModal
        trackingContext={trackingContext}
        trackingPlacement={TrackingPlacement.EngagementLimitsProgressBar}
      >
        <PopoverFooter px="space6" pb="space6">
          <RequestUpgradeButton
            onClick={onClick}
            variant="positive"
            iconLeading={StarSolidIcon}
            size="compact"
          />
        </PopoverFooter>
      </RequestUpgradeModal>
    );
  } else {
    return (
      <PopoverFooter px="space6" pb="space6">
        <UpgradeButton
          size="compact"
          onClick={() => {
            trackUser({
              event: 'Plans from homeview',
              properties: {
                context: 'Top header',
              },
            });
          }}
        />
      </PopoverFooter>
    );
  }
}
