import { z } from 'zod';
import { baseNotificationSchema } from '../../notification';

export const CREATED_COMMENT_TYPE = 'created-comment';
export const createdCommentSchema = baseNotificationSchema.extend({
  type: z.literal(CREATED_COMMENT_TYPE),
  payload: z.object({
    senderName: z.string(),
    senderEmail: z.string(),
    senderProfilePictureUrl: z.string().nullable().optional(),
    commentLink: z.string(),
    seriesName: z.string(),
  }),
});

export type CreatedCommentNotificationT = z.infer<typeof createdCommentSchema>;
