export interface PageIndex {
  title: string;
  url: string;
  keywords: string[];
}

export function getPages({
  showWorkspacePresentations,
  showFindAvailableTeams,
  workspacePresentationsFeatureTitle,
}: {
  showWorkspacePresentations: boolean;
  showFindAvailableTeams: boolean;
  workspacePresentationsFeatureTitle: string;
}): PageIndex[] {
  return [
    {
      title: 'Home',
      url: '/app/home',
      keywords: ['home', 'dashboard'],
    },
    {
      title: 'Private presentations',
      url: '/app/dashboard',
      keywords: ['private', 'presentations', 'dashboard'],
    },
    {
      title: 'Workspace settings',
      url: '/app/workspace-settings',
      keywords: ['workspace', 'settings'],
    },
    {
      title: 'Manage members',
      url: '/app/manage-members',
      keywords: [
        'manage',
        'members',
        'team',
        'invite',
        'accept',
        'reject',
        'settings',
      ],
    },
    ...(showWorkspacePresentations
      ? [
          {
            title: `${workspacePresentationsFeatureTitle}`,
            url: '/app/workspace',
            keywords: ['shared', 'team', 'presentations', 'dashboard'],
          },
        ]
      : []),
    ...(showFindAvailableTeams
      ? [
          {
            title: 'Find available teams',
            url: '/app/find-your-team',
            keywords: ['find', 'team', 'join', 'invite', 'settings'],
          },
        ]
      : []),
    {
      title: 'Profile settings',
      url: '/app/settings',
      keywords: ['profile', 'personal', 'account', 'settings'],
    },
    {
      title: 'Billing',
      url: '/app/billing',
      keywords: ['billing', 'payment', 'settings'],
    },
  ];
}
