import { useRagnar, type IRenderer } from '@mentimeter/ragnar-react';
import { BadgeWrap, Box, Tooltip, type BoxT } from '@mentimeter/ragnar-ui';

const animationStyle = (renderer: IRenderer) => {
  const blinkKeyframe =
    renderer.renderKeyframe &&
    renderer.renderKeyframe(
      () => ({
        '25%': { transform: 'scale(1.3)' },
        '50%': { transform: 'scale(0.7)' },
        '75%': { transform: 'scale(1.2)' },
        '80%': { transform: 'scale(0.8)' },
        '90%': { transform: 'scale(1.1)' },
        '95%': { transform: 'scale(0.9)' },
        '100%': { transform: 'scale(1)' },
      }),
      {},
    );

  return {
    animationDuration: '1s',
    animationIterationCount: 1,
    animationName: blinkKeyframe,
  };
};

const WigglingDot = ({
  refId,
  size = '13px',
  wiggle,
  bg,
  borderColor,
}: {
  refId: string;
  size?: string;
  wiggle: boolean;
  bg: BoxT['bg'];
  borderColor: BoxT['borderColor'];
}) => {
  const { renderer } = useRagnar();

  return (
    <Box
      id={refId}
      height={size}
      width={size}
      borderStyle="solid"
      borderColor={borderColor}
      borderWidth={2}
      borderRadius={3}
      bg={bg}
      style={wiggle ? animationStyle(renderer) : {}}
    />
  );
};

export const WigglingDotBadge = ({
  id,
  description,
  wiggle = true,
  variant = 'informative',
  placement = 0,
}: {
  id: string;
  description?: string;
  wiggle?: boolean;
  variant?: 'informative' | 'urgent';
  placement?: 0 | 1 | 2 | 3;
}) => {
  const dot =
    variant === 'informative' ? (
      <WigglingDot
        refId={id}
        wiggle={wiggle}
        bg="primary"
        borderColor="borderPrimaryWeakest"
      />
    ) : (
      <WigglingDot
        refId={id}
        wiggle={wiggle}
        bg="negative"
        borderColor="borderNegativeWeakest"
      />
    );

  return (
    <BadgeWrap placement={placement} badge={dot}>
      {description && (
        <Tooltip referenceId={id} placement="top" description={description} />
      )}
    </BadgeWrap>
  );
};
