import { z } from 'zod';
import { baseNotificationSchema } from '../../notification';

export const NEARING_ENGAGEMENT_LIMIT_TYPE = 'nearing-engagement-limit';
export const nearingEngagementLimitSchema = baseNotificationSchema.extend({
  type: z.literal(NEARING_ENGAGEMENT_LIMIT_TYPE),
  payload: z.object({
    engagedParticipantLimit: z.coerce.number(),
    limit: z.coerce.number(),
  }),
});

export type NearingEngagementLimitNotificationT = z.infer<
  typeof nearingEngagementLimitSchema
>;
