import type { Rule } from '@mentimeter/ragnar-styled';
import type { DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu';
import type { ContextMenuContentProps } from '@radix-ui/react-context-menu';
import {
  blackAlpha50,
  blackAlpha100,
  blackAlpha500,
} from '@mentimeter/ragnar-colors';

const contentShowBottom = () => ({
  '0%': { opacity: 0, transform: `translateY(-10px) scale(.9)` },
  '100%': { opacity: 1, transform: `translateY(0) scale(1)` },
});
const contentShowTop = () => ({
  '0%': { opacity: 0, transform: `translateY(10px) scale(.9)` },
  '100%': { opacity: 1, transform: `translateY(0) scale(1)` },
});
const contentShowLeft = () => ({
  '0%': { opacity: 0, transform: `translateX(10px) scale(.9)` },
  '100%': { opacity: 1, transform: `translateY(0) scale(1)` },
});
const contentShowRight = () => ({
  '0%': { opacity: 0, transform: `translateX(-10px) scale(.9)` },
  '100%': { opacity: 1, transform: `translateY(0) scale(1)` },
});

export const menuContentRule: Rule<
  DropdownMenuContentProps | ContextMenuContentProps
> = ({ theme }, renderer) => ({
  backgroundColor: theme.colors.surface,
  borderRadius: `${theme.kosmosBorderRadius.lg}px`,
  filter: `drop-shadow(0px 4px 4px ${blackAlpha50}) drop-shadow(0px 0px 1px ${blackAlpha500}) drop-shadow(0px 1px 1px ${blackAlpha100}) drop-shadow(0px 14px 32px ${blackAlpha50})`,
  padding: `${theme.kosmosSpacing.space4}px`,
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.kosmosSpacing['space0.5']}px`,
  zIndex: theme.zIndex.modal,
  maxWidth: `calc(100vw - ${theme.kosmosSpacing.space8}px)`,

  '&:focus': {
    outline: 'none',
  },
  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-side="bottom"]': {
      animation: renderer
        ? `${renderer.renderKeyframe(contentShowBottom, {})} ${
            theme.durations[0]
          }s`
        : undefined,
    },
    '&[data-side="top"]': {
      animation: renderer
        ? `${renderer.renderKeyframe(contentShowTop, {})} ${
            theme.durations[0]
          }s`
        : undefined,
    },
    '&[data-side="left"]': {
      animation: renderer
        ? `${renderer.renderKeyframe(contentShowLeft, {})} ${
            theme.durations[0]
          }s`
        : undefined,
    },
    '&[data-side="right"]': {
      animation: renderer
        ? `${renderer.renderKeyframe(contentShowRight, {})} ${
            theme.durations[0]
          }s`
        : undefined,
    },
  },
});
