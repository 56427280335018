import type { Rule } from '@mentimeter/ragnar-styled';
import { addUnit } from '@mentimeter/ragnar-utils';
import type { DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';
import type { ContextMenuItemProps } from '@radix-ui/react-context-menu';

export const menuItemRule: Rule<
  (ContextMenuItemProps | DropdownMenuItemProps) & {
    variant?: 'default' | 'negative';
  }
> = ({ theme, variant = 'default' }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: theme.colors.surface,
  color: variant === 'negative' ? theme.colors.textNegative : theme.colors.text,
  padding: `${theme.kosmosSpacing.space2}px`,
  transition: `all ${theme.durations[0]}s`,
  fontSize: addUnit(theme.fontSizes[1]),
  lineHeight: theme.lineHeights.body,
  fontFamily: 'MentiText',
  cursor: 'pointer',
  borderRadius: `${theme.kosmosBorderRadius.lg}px`,
  gap: `${theme.kosmosSpacing.space3}px`,
  '[data-state="checked"]': {
    color:
      variant === 'negative'
        ? theme.colors.onNegativeWeakest
        : theme.colors.onSecondaryWeakest,
    backgroundColor:
      variant === 'negative'
        ? theme.colors.negativeWeakest
        : theme.colors.secondaryWeakest,
    boxShadow: `0px 0px 0px 1px inset ${
      variant === 'negative' ? theme.colors.negative : theme.colors.secondary
    }`,
    '&:focus': {
      backgroundColor:
        variant === 'negative'
          ? theme.colors.negativeWeak
          : theme.colors.secondaryWeak,
      color:
        variant === 'negative'
          ? theme.colors.onNegativeWeak
          : theme.colors.onSecondaryWeak,
    },
  },
  '&[aria-disabled="true"]': {
    cursor: 'not-allowed',
    color: theme.colors.onDisabled,
    backgroundColor: theme.colors.disabled,
  },
  '&:focus': {
    outline: 'none',
    backgroundColor:
      variant === 'negative'
        ? theme.colors.negativeWeakest
        : theme.colors.secondaryWeakest,
    color:
      variant === 'negative'
        ? theme.colors.onNegativeWeakest
        : theme.colors.onSecondaryWeakest,
  },
});
