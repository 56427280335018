import { z } from 'zod';
import { baseNotificationSchema } from '../../notification';

export const JOINED_WORKSPACE_TYPE = 'joined-workspace';
export const joinedWorkspaceSchema = baseNotificationSchema.extend({
  type: z.literal(JOINED_WORKSPACE_TYPE),
  payload: z.object({
    senderName: z.string(),
    senderEmail: z.string(),
    senderProfilePictureUrl: z.string().nullable().optional(),
  }),
});

export type JoinedWorkspaceNotificationT = z.infer<
  typeof joinedWorkspaceSchema
>;
