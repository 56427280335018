import { type UserT } from '@mentimeter/user';
import { EngagementStatus } from '@mentimeter/paywalls-data-hooks';
import { isNewUser } from './isNewUser';

export function shouldShowEngagementLimitProgress(
  userCreatedAt: UserT['createdAt'],
  engagementStatus: EngagementStatus,
) {
  const showAtEngagementStatus = !isNewUser(userCreatedAt)
    ? EngagementStatus.Unengaged
    : EngagementStatus.ActivatedLimit;

  return engagementStatus >= showAtEngagementStatus;
}
