import { useDevelopmentFlag } from '@mentimeter/development-tools/hooks';
import type { NotificationT } from '../notifications/types';

export function useNotificationFixtures():
  | NotificationT[]
  | null
  | Error
  | 'loading' {
  const notificationFixture = useDevelopmentFlag('notification-fixtures');
  if (!notificationFixture) return null;

  switch (notificationFixture) {
    case 'loading-state':
      return 'loading';
    case 'error-state':
      return new Error('Something went wrong fetching notifications');
    case 'empty-state':
      return [];
    default:
      return null;
  }
}
