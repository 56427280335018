import { IconButton, type IconButtonProps } from '@mentimeter/ragnar-ui';
import { MenuIcon } from '@mentimeter/ragnar-visuals';

export function ToggleMobileMenuButton({
  setShowMenu,
  ...props
}: {
  setShowMenu: (show: boolean) => void;
} & IconButtonProps) {
  return (
    <IconButton
      size="large"
      variant="secondary"
      alignItems="flex-end"
      justifyContent="center"
      onClick={() => setShowMenu(true)}
      {...props}
    >
      <MenuIcon color="inherit" />
    </IconButton>
  );
}
