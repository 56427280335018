// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface GetNotificationsRequest {
  region: 'eu' | 'us';
  userAuth: string;
}

export function getNotificationsRequest(
  requestParams: GetNotificationsRequest,
): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/notifications`;
  return new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
