import type { SWRInfiniteConfiguration } from 'swr/infinite';
import type {
  PresentationQueryParamsBase,
  PresentationResponse,
  PresentationsResponse,
  PrivatePresentationsQueryParams,
} from '@mentimeter/http-clients';
import type { SWRConfiguration } from 'swr';
import type { FlagsT, GalleryPresentation } from './types';

export interface PresentationsBaseProps {
  page?: number;
  sortBy?: string;
  sortOrder?: string;
  folderId?: number | null;
}

export const swrPresentationPageFolderConfig: SWRConfiguration = {
  revalidateOnFocus: false,
  keepPreviousData: true,
};

export const swrPresentationPageConfig: SWRInfiniteConfiguration = {
  ...swrPresentationPageFolderConfig,
  revalidateAll: true,
};

const getQueryString = (params: PresentationsBaseProps) =>
  Object.entries(params)
    .map(([key, value]) => key + '=' + encodeURIComponent(value))
    .join('&');

export const getKey = (
  pageIndex: number,
  previousPageData: PresentationsResponse | null,
  params: PresentationsBaseProps | PrivatePresentationsQueryParams,
  path: 'me' | 'shared-with-me' | 'workspace',
) => {
  if (previousPageData?.data && !previousPageData.data?.length) return null;

  params.page = pageIndex + 1;
  return `/presentations/${path}?${getQueryString(params)}`;
};

const getFlagArray = (presentation: PresentationResponse): FlagsT[] => {
  const isTemplate = Boolean(
    presentation.isSharedWithOrganization ||
      Boolean(
        presentation.sharedWithGroups &&
          presentation.sharedWithGroups.length > 0,
      ),
  );

  return isTemplate ? [flagValues.TEMPLATE] : [];
};

export const appendBackwardCompatibleSeriesParams = (
  presentation: PresentationResponse,
  displayFlags?: boolean,
): GalleryPresentation => {
  return {
    ...presentation,
    flags: displayFlags ? getFlagArray(presentation) : [],
    numberOfItems: presentation.questionsCount,
    inUserSharedFolder: presentation.inUserSharedFolder,
  };
};

export const convertKeysCamelToSnake = (
  obj: PresentationsBaseProps,
): PresentationQueryParamsBase => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const snakeCaseKey = key.replace(
        /[A-Z]/g,
        (match) => `_${match.toLowerCase()}`,
      );
      return [snakeCaseKey, value];
    }),
  );
};

export const calculateHasReachedLastPage = (
  data: PresentationsResponse[] | undefined,
) => {
  if (!data) return false;

  const currentPage = data[data.length - 1]?.page;
  const totalPages = data[data.length - 1]?.pageCount;
  return data.length === 0 || currentPage === totalPages;
};

export const calculateIsLoadingMoreData = (
  page: number,
  data: PresentationsResponse[] | undefined,
) => {
  return page > 0 && Boolean(data && typeof data[page - 1] === 'undefined');
};

export const createPresentationsArray = (
  data: PresentationsResponse[] | undefined,
  displayFlags?: boolean,
): GalleryPresentation[] =>
  data?.flatMap(
    ({ data }: { data: PresentationResponse[] | undefined }) =>
      data?.map((presentation) =>
        appendBackwardCompatibleSeriesParams(presentation, displayFlags),
      ) || [],
  ) || [];

export const flagValues = Object.freeze({
  SHARED: 'shared',
  NEW: 'new',
  TEMPLATE: 'template',
  VIEW_ONLY: 'view-only',
  ARCHIVED: 'archived',
  ADD_SLIDE_POPOVER: 'add slide popover',
  PUBLISHED_EXAMPLE: 'published example',
});
