'use client';

import { Text } from '@mentimeter/ragnar-ui';
import { BellIcon, UsersIcon } from '@mentimeter/ragnar-visuals';
import { match } from 'ts-pattern';
import createDOMPurify from 'dompurify';
import {
  getTimeAgo,
  NotificationAvatar,
  NotificationBody,
  NotificationContainer,
  NotificationHeader,
  NotificationIcon,
  NotificationLink,
} from '../../notification';
import { sanitizeUrl } from '../../notification/utils';
import type { GenericNotificationT } from './schema';

const ALLOWED_ATTRIBUTES: string[] = [];
const ALLOWED_TAGS: string[] = ['p', 'span', 'br', 'strong', 'em'];

// DOMPurify, by default, creates a global instance of itself. To use different configs, one for actions and one for body, we need to create individual instances.
// We do not want to create a new instance on every render, so we create one here as a singleton and another one inside the sanitizeUrl file.
const purifier = createDOMPurify();

export const GenericNotification = ({
  notification: {
    id,
    status,
    timestamp,
    actors,
    blocks: [bodyBlock, actionBlock],
  },
  onClick,
}: {
  notification: GenericNotificationT;
  onClick: () => void;
}) => {
  return (
    <NotificationLink
      href={sanitizeUrl(actionBlock.rendered, '#')}
      onClick={onClick}
    >
      <NotificationContainer key={id}>
        <NotificationHeader>
          {match(actors.length)
            .with(0, () => (
              <NotificationIcon
                testId="notification-fallback-icon"
                icon={<BellIcon size="20px" color="onInfoWeak" />}
              />
            ))
            .with(1, () => (
              <NotificationAvatar
                testId="notification-user-avatar"
                name={actors[0]!.name}
                profilePictureUrl={actors[0]!.profilePictureUrl}
              />
            ))
            .otherwise(() => (
              <NotificationIcon
                testId="notification-group-icon"
                icon={<UsersIcon size="20px" color="onInfoWeak" />}
              />
            ))}
        </NotificationHeader>
        <NotificationBody status={status}>
          {bodyBlock.rendered && (
            <Text
              dangerouslySetInnerHTML={{
                __html: purifier.sanitize(bodyBlock.rendered, {
                  ALLOWED_ATTR: ALLOWED_ATTRIBUTES,
                  ALLOWED_TAGS,
                }),
              }}
            />
          )}
          <Text color="textWeaker">{getTimeAgo(timestamp)} ago</Text>
        </NotificationBody>
      </NotificationContainer>
    </NotificationLink>
  );
};
