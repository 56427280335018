// This file is automatically generated. DO NOT EDIT.

import { type SearchResult } from '../types/response/SearchResult';
import { fromSnakeKeys } from '@api/internal';

export interface PostSearchResponse {
  /** The search results */
  results: Array<SearchResult>;
}

export async function postSearchResponse(
  response: Response,
): Promise<PostSearchResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostSearchResponse;
}
