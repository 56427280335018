import { z } from 'zod';
import { baseNotificationSchema } from '../../notification';

const bodyBlockSchema = z.object({
  name: z.literal('body'),
  type: z.literal('markdown'),
  rendered: z.string().min(1),
  content: z.string().min(1),
});

const actionUrlBlockSchema = z.object({
  name: z.literal('action_url'),
  type: z.literal('text'),
  rendered: z.string().min(1),
  content: z.string().min(1),
});

export const genericSchema = baseNotificationSchema.extend({
  type: z.string(),
  payload: z.any(),
  blocks: z.tuple([bodyBlockSchema, actionUrlBlockSchema]),
});

export type GenericNotificationT = z.infer<typeof genericSchema>;
