import { z } from 'zod';
import { baseNotificationSchema } from '../../notification';

export const RESOLVED_COMMENT_TYPE = 'resolved-comment';
export const resolvedCommentSchema = baseNotificationSchema.extend({
  type: z.literal(RESOLVED_COMMENT_TYPE),
  payload: z.object({
    senderName: z.string().nullable().optional(),
    senderEmail: z.string(),
    senderProfilePictureUrl: z.string().nullable().optional(),
    commentLink: z.string(),
    seriesName: z.string(),
  }),
});

export type ResolvedCommentNotificationT = z.infer<
  typeof resolvedCommentSchema
>;
