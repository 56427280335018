import { JoinedWorkspaceNotification } from './joined-workspace';
import { NearingEngagementLimitNotification } from './nearing-engagement-limit';
import { SeriesResultsNotification } from './series-results';
import type { NotificationMap } from './types';
import { InvitedToResourceNotification } from './invited-to-resource';
import { InvitedToWorkspaceNotification } from './invited-to-workspace';
import { RequestedToJoinWorkspaceNotification } from './requested-to-join-workspace';
import { AssignedNewRoleNotification } from './assigned-new-role';
import { CreatedCommentNotification } from './created-comment';
import { RepliedToCommentNotification } from './replied-to-comment';
import { ResolvedCommentNotification } from './resolved-comment';

export const NOTIFICATION_MAP: Readonly<NotificationMap> = Object.freeze({
  'joined-workspace': JoinedWorkspaceNotification,
  'nearing-engagement-limit': NearingEngagementLimitNotification,
  'series-results': SeriesResultsNotification,
  'invited-to-resource': InvitedToResourceNotification,
  'invited-to-workspace': InvitedToWorkspaceNotification,
  'requested-to-join-workspace': RequestedToJoinWorkspaceNotification,
  'assigned-new-role': AssignedNewRoleNotification,
  'created-comment': CreatedCommentNotification,
  'replied-to-comment': RepliedToCommentNotification,
  'resolved-comment': ResolvedCommentNotification,
});
