import { z } from 'zod';
import { baseNotificationSchema } from '../../notification';

export const REQUESTED_TO_JOIN_WORKSPACE_TYPE = 'requested-to-join-workspace';

export const requestedToJoinWorkspaceSchema = baseNotificationSchema.extend({
  type: z.literal(REQUESTED_TO_JOIN_WORKSPACE_TYPE),
  payload: z.object({
    senderName: z.string(),
    senderEmail: z.string(),
    senderProfilePictureUrl: z.string().nullable().optional(),
  }),
});

export type RequestedToJoinWorkspaceNotificationT = z.infer<
  typeof requestedToJoinWorkspaceSchema
>;
