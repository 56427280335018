import { userCache } from '@mentimeter/user';
import { postMarkAllRequest } from '@core-api/notifications/notifications/mark-all';
import useSWRMutation from 'swr/mutation';
import { useDevelopmentFlag } from '@mentimeter/development-tools/hooks';
import { NOTIFICATIONS_CACHE_KEY } from './constants';

export function useMarkAllNotifications(status: 'seen' | 'read') {
  const notificationFixtures = useDevelopmentFlag('notification-fixtures');
  const { trigger: markAllAsSeen } = useSWRMutation(
    NOTIFICATIONS_CACHE_KEY,
    async () => {
      const region = userCache.region;
      const sessionToken = userCache.getToken();

      await fetch(
        postMarkAllRequest({ region, userAuth: sessionToken, status }),
      );
    },
  );

  if (notificationFixtures) {
    return () => {
      // eslint-disable-next-line no-console
      console.log(`Marking all notifications as ${status}`);
    };
  }

  return markAllAsSeen;
}
