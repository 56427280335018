import { useEffect } from 'react';

/**
 * @param {string[]} selectors - Selectors of the element(s) to check if clicked outside of
 * @param {function(): void} callback - Recommend to wrap this function in React.useCallback
 */
export const useOutsideClick = (
  selectors: Array<string>,
  callback: (arg0: MouseEvent) => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const elementsInside = Array.from(
        document.querySelectorAll(selectors.join(',')),
      );

      const clickIsInside = elementsInside.some(
        (element) => element && element.contains(event.target as HTMLElement),
      );

      if (!clickIsInside) callback(event);
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [selectors, callback]);
};
