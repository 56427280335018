import { useMobileMenu } from '@mentimeter/__app-split-features/shell/components/mobile-menu/MobileMenuProvider';
import { LinkClickable, usePathname } from '@mentimeter/next-navigation';
import { useMatch } from '@mentimeter/ragnar-device';
import { Box, type BoxT } from '@mentimeter/ragnar-ui';
import { MentimeterSymbol } from '@mentimeter/ragnar-visuals';
import { useUser } from '@mentimeter/user';
import { EngagementLimitProgressBubble } from '@mentimeter/paywalls/engagement-limit';
import { UserActionsUpgradeButton } from '@mentimeter/paywalls/buttons';
import { contextFromPathname } from '@mentimeter/http-clients';
import { NotificationCenterDataHandler } from '@mentimeter/notifications/center';
import { useWorkspaceInviteRequests } from 'features/manage-members/member-requests/useWorkspaceInviteRequests';
import { useExperiment } from '@mentimeter/splitio';
import type { PropsWithChildren } from 'react';
import { JoinWorkspaceRequestNotification } from '../JoinWorkspaceRequestNotification';
import { ToggleMobileMenuButton } from './components/ToggleMobileMenuButton';
import { UserMenuLoading } from './components/UserMenuLoading';
import { UserMenu } from './components/user-menu/UserMenu';
import { Search } from './search/Search';

const HomeUserActionsSection = ({
  children,
  ...props
}: PropsWithChildren & BoxT) => {
  return (
    <Box
      flexDirection="row"
      height="56px"
      alignItems="center"
      px="space2"
      gap="space3"
      {...props}
    >
      {children}
    </Box>
  );
};

export const HomeUserActions = () => {
  const pathname = usePathname();
  const { user } = useUser();
  const { open: openMobileMenu } = useMobileMenu();
  const isMobile = useMatch({ lessThan: 3 });
  const { data: requests } = useWorkspaceInviteRequests({
    workspaceId: user?.workspaceId,
    isOwnerOrAdmin: !!user?.ownerOrAdmin,
  });

  const { isInExperiment: isInNewLimitBannerExperiment } = useExperiment(
    'PUG_New_Limit_Experience',
    ['new-banner'],
  );

  const { isInExperiment: isInNotificationExperiment } = useExperiment(
    'PUG_Notifications_Technical_Test',
    ['v1', 'v2'],
  );

  if (!user) return <UserMenuLoading />;

  const validPaths = ['/app/home', '/app/shared-with-me'];
  const showSearch = validPaths.some((path) => pathname.startsWith(path));
  const showJoinWorkspaceNotifications =
    !isInNotificationExperiment && !!requests && requests.length > 0;

  const trackingContext = contextFromPathname(pathname);

  return (
    <Box
      width="100%"
      justifyContent="space-between"
      flexDirection="row"
      alignItems="stretch"
      gap={4}
    >
      {!isMobile && (
        <>
          <HomeUserActionsSection flex={1}>
            {showSearch && <Search />}
          </HomeUserActionsSection>

          <HomeUserActionsSection alignSelf="flex-end">
            <UserActionsUpgradeButton trackingContext={trackingContext} />

            {!isInNewLimitBannerExperiment && (
              <Box alignSelf="baseline">
                <EngagementLimitProgressBubble
                  trackingContext={trackingContext}
                />
              </Box>
            )}

            {showJoinWorkspaceNotifications && (
              <JoinWorkspaceRequestNotification />
            )}
            {isInNotificationExperiment && (
              <NotificationCenterDataHandler
                trackingContext={trackingContext}
              />
            )}

            <UserMenu />
          </HomeUserActionsSection>
        </>
      )}

      {isMobile && (
        <Box
          width="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          height="56px"
        >
          <LinkClickable href="/app" p="space3">
            <MentimeterSymbol width={32} height={32} />
          </LinkClickable>
          <ToggleMobileMenuButton
            setShowMenu={(open) => {
              if (open) {
                openMobileMenu();
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
};
