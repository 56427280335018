import React from 'react';
import { useSplits } from '@mentimeter/splitio';
import {
  Clickable,
  Text,
  Link,
  PopoverBody,
  PopoverContainer,
  PopoverRoot,
  PopoverTrigger,
} from '@mentimeter/ragnar-ui';
import { BellIcon } from '@mentimeter/ragnar-visuals';
import { WigglingDotBadge } from 'components/WigglingDotBadge';

export function JoinWorkspaceRequestNotification() {
  const { Mango_Request_Page_V2 } = useSplits(['Mango_Request_Page_V2']);
  const isRequestsPageEnabled = Mango_Request_Page_V2 === 'on';

  const link = isRequestsPageEnabled
    ? '/app/requests'
    : '/app/manage-members?tab=requests';

  return (
    <PopoverRoot>
      <PopoverTrigger asChild>
        <Clickable flexDirection="row" alignSelf="center">
          <BellIcon size={4} />
          <WigglingDotBadge id="pending-requests-notification" wiggle={true} />
        </Clickable>
      </PopoverTrigger>
      <PopoverContainer align="end">
        <PopoverBody width="250px">
          <Text>
            People want to collaborate with you.{' '}
            <Link color="text" href={link}>
              See requests
            </Link>
          </Text>
        </PopoverBody>
      </PopoverContainer>
    </PopoverRoot>
  );
}
