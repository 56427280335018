import { z } from 'zod';
import { baseNotificationSchema } from '../../notification';

export const ASSIGNED_NEW_ROLE_TYPE = 'assigned-new-role';

const RoleZ = z.union([
  z.literal('admin'),
  z.literal('member_lite'),
  z.literal('user'),
]);

export const assignedNewRoleSchema = baseNotificationSchema.extend({
  type: z.literal(ASSIGNED_NEW_ROLE_TYPE),
  payload: z.object({
    senderName: z.string(),
    senderEmail: z.string(),
    senderProfilePictureUrl: z.string().nullable().optional(),
    previousRole: RoleZ,
    newRole: RoleZ,
  }),
});

export type AssignedNewRoleNotificationT = z.infer<
  typeof assignedNewRoleSchema
>;
