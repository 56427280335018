// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostMarkReadRequest {
  region: 'eu' | 'us';
  userAuth: string;
  notificationId: string;
}

export function postMarkReadRequest(
  requestParams: PostMarkReadRequest,
): Request {
  const data = {
    notification_id: toSnakeKeys(requestParams.notificationId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/notifications/mark-read`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
