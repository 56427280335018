import { Link } from '@mentimeter/next-navigation';
import React from 'react';

export function NotificationLink({
  children,
  href,
  onClick,
}: {
  children: React.ReactNode;
  href: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}) {
  return (
    <Link
      width="100%"
      href={href}
      onClick={onClick}
      underline={false}
      extend={() => ({
        ':hover': {
          opacity: 1,
        },
      })}
    >
      {children}
    </Link>
  );
}
