import * as React from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import { Content } from '@radix-ui/react-dropdown-menu';
import type { ComponentProps } from 'react';
import { menuContentRule } from '../menuContentRule';

const DropdownMenuContentRadixStyled = styled(
  Content,
  {
    displayName: 'DropdownMenuContentRadixStyled',
  },
  true,
  [
    'loop',
    'forceMount',
    'side',
    'sideOffset',
    'align',
    'alignOffset',
    'avoidCollisions',
    'collisionBoundary',
    'collisionPadding',
    'arrowPadding',
    'sticky',
    'hideWhenDetached',
  ],
)(menuContentRule, 'color', 'kosmosSpacing', 'layout', 'flexbox', 'position');

export type DropdownMenuContentProps = ComponentProps<
  typeof DropdownMenuContentRadixStyled
>;

export const DropdownMenuContent = React.forwardRef(
  (props: DropdownMenuContentProps, forwardedRef) => (
    <DropdownMenuContentRadixStyled
      ref={forwardedRef}
      loop
      collisionPadding={16}
      arrowPadding={4}
      sideOffset={1}
      {...props}
    />
  ),
);
