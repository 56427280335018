import { z } from 'zod';

const userActorSchema = z.object({
  type: z.literal('user'),
  email: z.string(),
  name: z.string(),
  profilePictureUrl: z.string().nullable(),
});

const systemActorSchema = z.object({
  type: z.literal('system'),
  name: z.string(),
  profilePictureUrl: z.string().nullable(),
});

const actorSchema = z.discriminatedUnion('type', [
  userActorSchema,
  systemActorSchema,
]);

export const baseNotificationSchema = z.object({
  id: z.string(),
  actors: z.array(actorSchema),
  timestamp: z.string(),
  status: z.enum(['seen', 'read', 'sent']),
});

export type BaseNotificationT = z.infer<typeof baseNotificationSchema>;
