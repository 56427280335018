import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import { Arrow } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuArrowProps as DropdownMenuArrowPropsRadix } from '@radix-ui/react-dropdown-menu';
import type { ComponentProps } from 'react';

const dropdownMenuArrowRule: Rule<DropdownMenuArrowPropsRadix> = ({
  theme,
}) => ({
  fill: theme.colors.surface,
});

const DropdownMenuArrowRadixStyled = styled(
  Arrow,
  {
    displayName: 'DropdownMenuArrowRadixStyled',
  },
  true,
)(dropdownMenuArrowRule, 'color', 'kosmosSpacing');

export type DropdownMenuArrowProps = ComponentProps<
  typeof DropdownMenuArrowRadixStyled
>;

export const DropdownMenuArrow = DropdownMenuArrowRadixStyled;
