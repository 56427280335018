import { z } from 'zod';
import { baseNotificationSchema } from '../../notification';

export const SERIES_RESULTS_TYPE = 'series-results';
export const seriesResultsSchema = baseNotificationSchema.extend({
  type: z.literal(SERIES_RESULTS_TYPE),
  payload: z.object({
    seriesId: z.string(),
    seriesName: z.string(),
    numberOfVotes: z.number(),
  }),
});

export type SeriesResultsNotificationT = z.infer<typeof seriesResultsSchema>;
