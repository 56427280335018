import { styled } from '@mentimeter/ragnar-styled';
import { Item } from '@radix-ui/react-dropdown-menu';
import type { ComponentProps } from 'react';
import { menuItemRule } from '../menuItemRule';

const DropdownMenuItemRadixStyled = styled(
  Item,
  {
    displayName: 'DropdownMenuItemRadixStyled',
  },
  true,
  ['asChild', 'disabled', 'textValue'],
)(menuItemRule, 'color', 'kosmosSpacing', 'layout', 'flexbox');

export type DropdownMenuItemProps = ComponentProps<
  typeof DropdownMenuItemRadixStyled
>;

export const DropdownMenuItem = DropdownMenuItemRadixStyled;
