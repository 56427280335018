import { useWorkspace } from '@mentimeter/workspace-data-hooks';

export const useWorkspacePresentationsFeatureTitle = () => {
  const { data: workspace, isLoading: isLoadingWorkspace } = useWorkspace();

  return {
    isLoading: isLoadingWorkspace,
    workspacePresentationsFeatureTitle: `${workspace?.name ?? 'Workspace'} presentations`,
  };
};
