// This file is automatically generated. DO NOT EDIT.

import { type Notification } from '../types/response/Notification';
import { fromSnakeKeys } from '@api/internal';

export interface GetNotificationsResponse {
  notifications: Array<Notification>;
}

export async function getNotificationsResponse(
  response: Response,
): Promise<GetNotificationsResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as GetNotificationsResponse;
}
