import { Text } from '@mentimeter/ragnar-ui';
import {
  getTimeAgo,
  NotificationBody,
  NotificationContainer,
  NotificationAvatar,
  NotificationLink,
  NotificationHeader,
} from '../../notification';
import type { InvitedToWorkspaceNotificationT } from './schema';

export const InvitedToWorkspaceNotification = ({
  notification: {
    id,
    timestamp,
    payload: {
      senderName,
      senderProfilePictureUrl,
      inviteLink,
      workspaceName,
      seriesName,
    },
    status,
  },
  onClick,
}: {
  notification: InvitedToWorkspaceNotificationT;
  onClick: () => void;
}) => {
  return (
    <NotificationLink href={inviteLink} onClick={onClick}>
      <NotificationContainer key={id}>
        <NotificationHeader>
          <NotificationAvatar
            name={senderName}
            profilePictureUrl={senderProfilePictureUrl}
          />
        </NotificationHeader>
        <NotificationBody status={status}>
          <Text>
            {seriesName ? (
              <>
                <Text fontWeight="semiBold">{senderName}</Text> invited you to
                edit <Text fontWeight="semiBold">{seriesName}</Text>.
              </>
            ) : workspaceName ? (
              <>
                <Text fontWeight="semiBold">{senderName}</Text> invited you to
                join <Text fontWeight="semiBold">{workspaceName}</Text>.
              </>
            ) : (
              <>
                <Text fontWeight="semiBold">{senderName}</Text> invited you to
                join their workspace.
              </>
            )}
          </Text>
          <Text color="textWeaker">{getTimeAgo(timestamp)} ago</Text>
        </NotificationBody>
      </NotificationContainer>
    </NotificationLink>
  );
};
