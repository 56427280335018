import { z } from 'zod';
import { baseNotificationSchema } from '../../notification';

export const REPLIED_TO_COMMENT_TYPE = 'replied-to-comment';
export const repliedToCommentSchema = baseNotificationSchema.extend({
  type: z.literal(REPLIED_TO_COMMENT_TYPE),
  payload: z.object({
    senderName: z.string(),
    senderEmail: z.string(),
    senderProfilePictureUrl: z.string().nullable().optional(),
    commentLink: z.string(),
    seriesName: z.string(),
  }),
});

export type RepliedToCommentNotificationT = z.infer<
  typeof repliedToCommentSchema
>;
