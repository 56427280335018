import type { LinkClickableT } from '@mentimeter/next-navigation';
import { LinkClickable } from '@mentimeter/next-navigation';
import {
  DropdownMenuItem,
  Text,
  type DropdownMenuItemProps,
} from '@mentimeter/ragnar-ui';
import React, { Fragment } from 'react';

export interface UserMenuItemT
  extends LinkClickableT,
    Pick<DropdownMenuItemProps, 'variant'> {
  key: string;
  disabled?: boolean;
  href: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler | undefined;
  ariaLabel: string;
}
export function UserMenuItem({
  dismiss,
  action: {
    variant = 'default',
    key,
    disabled,
    href,
    onClick,
    ariaLabel,
    children,
    ...props
  },
  index,
}: {
  dismiss: () => void;
  action: UserMenuItemT;
  index: number;
}) {
  return (
    <Fragment key={key}>
      <DropdownMenuItem
        id={`action-${index}-${key}`}
        disabled={!!disabled}
        variant={variant}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(e);
          dismiss();
        }}
        aria-label={ariaLabel}
        extend={() => ({ userSelect: 'none' })}
        p={0}
        m={0}
      >
        <LinkClickable
          p="space2"
          href={href}
          color="currentColor"
          flex={1}
          flexDirection="row"
          alignItems="center"
          extend={() => ({
            '@media (hover: hover)': {
              ':hover': {
                opacity: 1,
              },
            },
          })}
          {...props}
        >
          <Text truncate color="currentColor">
            {children}
          </Text>
        </LinkClickable>
      </DropdownMenuItem>
    </Fragment>
  );
}
