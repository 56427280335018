import { z } from 'zod';
import { joinedWorkspaceSchema } from './joined-workspace';
import { nearingEngagementLimitSchema } from './nearing-engagement-limit';
import { seriesResultsSchema } from './series-results';
import { invitedToResourceSchema } from './invited-to-resource';
import { invitedToWorkspaceSchema } from './invited-to-workspace';
import { requestedToJoinWorkspaceSchema } from './requested-to-join-workspace';
import { assignedNewRoleSchema } from './assigned-new-role';
import { createdCommentSchema } from './created-comment';
import { repliedToCommentSchema } from './replied-to-comment';
import { resolvedCommentSchema } from './resolved-comment';

export const notificationSchema = z.union([
  joinedWorkspaceSchema,
  nearingEngagementLimitSchema,
  seriesResultsSchema,
  invitedToResourceSchema,
  invitedToWorkspaceSchema,
  requestedToJoinWorkspaceSchema,
  assignedNewRoleSchema,
  createdCommentSchema,
  repliedToCommentSchema,
  resolvedCommentSchema,
]);
